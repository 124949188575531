//-- Color palette --
$color-red:          #d0021b;

$color-gray:         #68757e;
$color-gray-dark:    #4a535a;
$color-gray-darker:  #373e43;
$color-gray-light:   #c3c8cb;
$color-gray-lighter: #f4f4f4;

$color-white:       #fff;

$color-black:       #000;

$color-shadow:      rgba(0, 0, 0, 0.05);

//-- Color settings --
$color-ui:          $color-gray-lighter;

$color-accent:      $color-black;

$color-text:        $color-black;
$color-text-light:  $color-white;

$color-link:        $color-gray;
$color-link-hover:  $color-black;

$color-error:       $color-red;

//-- Effects --
$style-shadow: 0 1px 4px 0 rgba(0, 0, 0, .1), 0 -1px 4px 0 rgba(0, 0, 0, .1);
$style-transition-timing: 150ms ease;

// Breakpoints
$max-width: 1600px;
$desktop-width: 1021px;
$tablet-width: 1020px;
$mobile-width: 767px;

// paddings & margins
$page-space: 52px;
$xxl-space: 100px;
$xl-space: 60px;
$l-space: 50px;
$m-space: 30px;
$s-space: 20px;
$xs-space: 15px;
$xxs-space: 10px;
$xxxs-space: 5px;

// font weights
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

// font-familys
$font-primary: 'AktivGroteskRegular', Arial, Sans-Serif;
$font-header-large: 'PublicoBannerBold', Serif;
$font-article-title: 'PublicoBannerRoman', Serif;
$font-article-body: 'PublicoTextRoman', Serif;
$font-category: 'AktivGroteskBold', Arial, Sans-Serif;
$font-author-name: 'Bebasneue', Arial;


// z-indexes
$z-negative: -1;
$z-low: 1;
$z-mid: 10;
$z-high: 20;

// borders
$border: 1px solid $color-gray;
$border-color: $color-gray;

// border radius
$border-radius-small: $xxxs-space;
$border-radius-large: 50%;

// animation timings
$animation2s: .2s;
$animation4s: .4s;

// navigation sizes
$navigation-width: 512px;
$navigation-width-mobile: 100%;
$navigation-height: 72px;
