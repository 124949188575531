.c-contributor {

  &-about {
    @include flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto $m-space;
    text-align: center;

    @media screen and (max-width: $tablet-width) {
      .heading-large {
        font-size: rem-calc(24);
        line-height: 30px;
      }
    }
  }

  &-list {
    @include flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
    align-items: baseline;
  }

  &-card {
    flex-grow: 1;
    margin-bottom: $m-space;
    max-width: 200px;
    min-width: 166px;
    padding: 0 16px;
    text-align: center;
    width: 16.6%;

    img {
      flex: 1;
      height: 205px;
      width: 166px;
    }

    a {
      color: $color-link-hover;
      text-decoration: none;

      &:hover,
      &:focus {
        color: $color-link;
        text-decoration: underline;
      }
    }
    @media screen and (max-width: $mobile-width) {
      width: 50%;

      &:nth-child(odd) {
        padding: 0 5px 0 0;
      }

      &:nth-child(even) {
        padding: 0 0 0 5px;
      }
    }
  }
}
