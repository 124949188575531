.c-right-rail {
  padding-top: $l-space;

  @media screen and (max-width: $tablet-width) {
    padding-top: 0;
  }

  @media screen and (max-width: $tablet-width) and (min-width: $mobile-width) {
    @include flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  section {
    margin-bottom: $xl-space;
  }

  .c-social-bar {
    border: 1px solid $color-gray-light;
    justify-content: space-around;
    margin: 0 0 $l-space;
    padding: $xs-space;
    font-family: $font-category;

    @media screen and (max-width: $tablet-width) and (min-width: $mobile-width) {
      justify-content: center;
    }

    @media screen and (max-width: $mobile-width) {
      padding: $xxs-space;
    }
  }
}
