@font-face {
  font-display: fallback;
  font-family: 'AktivGroteskRegular';
  src: url('../fonts/aktivgroteskregular-webfont.woff2') format('woff2'),
  url('../fonts/aktivgroteskregular-webfont.woff') format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'AktivGroteskBold';
  src: url('../fonts/aktivgroteskbold-webfont.woff2') format('woff2'),
  url( '../fonts/aktivgroteskbold-webfont.woff') format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'Bebasneue';
  src: url('../fonts/bebasneue-webfont.woff2') format('woff2'),
  url('../fonts/bebasneue-webfont.woff')  format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'PublicoTextItalic';
  src: url('../fonts/publicotextitalic-webfont.woff2') format('woff2'),
  url('../fonts/publicotextitalic-webfont.woff')  format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'PublicoTextRoman';
  src: url('../fonts/publicotextroman-webfont.woff2') format('woff2'),
  url('../fonts/publicotextroman-webfont.woff')  format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'PublicoBannerBold';
  font-weight: bold;
  src: url('../fonts/PublicoBannerBold.woff2') format('woff2'),
  url('../fonts/PublicoBannerBold.woff') format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'PublicoBannerRoman';
  src: url('../fonts/PublicoBannerRoman.woff2') format('woff2'),
  url('../fonts/PublicoBannerRoman.woff') format('woff');
}
