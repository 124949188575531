.c-feature {
  &-image {
    margin-top: $m-space;

    &-description {
      display: block;
      font-style: italic;
    }
  }
}
