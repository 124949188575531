.c-ad {
  &-container {
    margin: 0 auto $l-space;

    .double-bigbox {
      background: rgba(0, 0, 0, .1);
      display: flex;
      height: 600px;
      max-width: 100%;
      width: 300px;
    }

    .leaderboard {
      background: rgba(0, 0, 0, .1);
      display: flex;
      height: 90px;
      max-width: 100%;
      width: 728px;
    }
  }
}
