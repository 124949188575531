.c-search {
  &-container {
    animation: grow-up .35s ease-in-out;
    background: $color-gray-darker;
    height: 0;
    justify-content: center;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: $navigation-height;
    width: 100%;
    z-index: $z-mid;

    .searchBox {
      @include flex;
      height: 100%;
      justify-content: center;
      width: 100%;
    }

    &.active {
      animation: grow-down .35s ease-in-out;
      height: $navigation-height;
    }
  }

  &-input {
    background: $color-gray-darker;
    border: 0;
    border-left: 1px solid $color-gray;
    color: $color-white;
    padding-left: $m-space;
    width: 35%;

    @media screen and (max-width: $mobile-width) {
      width: calc(100% - 117px);
    }
  }

  &-button {
    background: $color-gray-darker;
    border: 0;
    border-left: 1px solid $color-gray;
    border-right: 1px solid $color-gray;
    color: $color-white;
    font-family: $font-author-name;
    height: $navigation-height;
    padding: 0 $m-space;

    &:hover,
    &:focus {
      // background-color: $color-gray-dark;
      text-decoration: underline;
    }

    @media screen and (max-width: $mobile-width) {
      width: 117px;
    }
  }
}
