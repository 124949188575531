// SETTINGS
@import 'settings/fonts';
@import "settings/settings";
@import "_foundation/foundation";

// TOOLS
@import "tools/mixins";
@import "tools/functions";
@import "tools/placeholders";

//COMPONENTS
@import 'components/ads';
@import 'components/article-list';
@import 'components/articles-related';
@import 'components/contributors';
@import 'components/cta-list';
@import 'components/feature-image';
@import 'components/featured-subarticle';
@import 'components/footer';
@import 'components/load-more';
@import 'components/right-rail';
@import 'components/search-bar';

// TRUMPS
@include foundation-flex-classes;
@include foundation-visibility-classes;
@import 'trumps/ckEditor';
@import 'trumps/helper';

// SCOPES
.defer {
  opacity: 1;
}
