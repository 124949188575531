.c-footer {
  @include flex;
  background: $black;
  color: $color-white;
  justify-content: center;
  padding: $l-space 0;

  &-container {
    @include flex;
    justify-content: space-between;
    max-width: 1280px;
    width: 100%;
  }

  &-main {
    &-nav {
      border-left: 1px solid $color-gray-dark;
      padding: 0 0 0 $l-space;

      @media screen and (max-width: $mobile-width) {
        border-left: 0;
        padding: 0 $m-space $m-space 0;

        ul {
          padding-left: 0;
        }
      }

      .c-footer-nav {
        margin-bottom: $m-space;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &-secondary {
    &-nav {
      margin-bottom: $m-space;
      padding-left: 0;
    }
  }

  a {
    color: $color-white;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $color-gray-light;
      text-decoration: underline;
    }
  }

  li {
    list-style: none;
  }

  .o-grid-column-3 {
    &.flex-container {
      justify-content: space-around;

      @media screen and (max-width: $mobile-width) {
        justify-content: flex-start;
        flex-wrap: wrap;
      }
    }
  }

  &-logo {
    &-container {
      margin-bottom: $l-space;
      max-width: 150px;
      width: 50%;

      @media screen and (max-width: $tablet-width) and (min-width: $mobile-width) {
        margin-top: $l-space;
      }
    }
  }

  &-about {
    a {
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  @media screen and (max-width: $tablet-width) and (min-width: $mobile-width) {
    .o-grid-column-3 {
      flex: 1;
      width: auto;
    }

    .o-grid-column-1 {
      width: auto;
    }
  }
}
