.c-list {

  &-articles {
    @include flex-column;
    padding-top: $l-space;
  }

  &-article {
    animation: fade-in .35s ease-in;

    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;

      .c-list-article-content {
        border-bottom: 0;
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: $mobile-width) {
      border-bottom: 1px solid $color-gray-light;
      margin-bottom: $m-space;

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;

        .c-list-article-content {
          border-bottom: 0;
          margin-bottom: 0;
        }
      }
    }

    &-title {
      @include flex-column;
      margin-bottom: $xs-space;

      @media screen and (max-width: $tablet-width) {
        font-size: rem-calc(20px);
      }
    }

    &-image {
      max-width: 222px;
      min-width: 222px;

      @media screen and (max-width: $mobile-width) {
        max-width: 100px;
        min-width: 100px;
      }
    }

    &-content {
      border-bottom: 1px solid $color-gray-light;
      flex-grow: 1;
      margin-bottom: $s-space;
      margin-left: $l-space;
      padding: $s-space 0 $m-space;
      width: calc(100% - 222px);

      @media screen and (max-width: $mobile-width) {
        border-bottom: 0;
        justify-content: space-around;
        margin-left: 15px;
        padding-bottom: 0;
        padding-top: 0;
        width: calc(100% - 130px);
      }

      .heading-large {
        color: $color-link-hover;
        text-decoration: none;

        font-size: rem-calc(28px);
        line-height: 34px;


        &:hover,
        &:focus {
          color: $color-link;
          text-decoration: underline;
        }

        @media screen and (max-width: $mobile-width) {
          font-size: rem-calc(20px);
          line-height: 26px;
        }
      }
    }
  }

  &-search {
    .c-list-articles {
      padding-top: 0;
    }
    .c-list-article {
      &-content {
        margin-left: 0;
      }

      &-title {
        margin-bottom: $xs-space;
      }
    }
  }
}
