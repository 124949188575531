// layout
@mixin flex-column {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

@mixin flex-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

@mixin flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

// lists
@mixin list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0;
  }
}

// paddings
@mixin padding {
  padding: rem-calc($padding-y) rem-calc($padding-x);
  @include breakpoint(large) {
    padding: rem-calc($padding-y-large) rem-calc($padding-x-large);
  }
}

@mixin padding-x {
  padding: 0 rem-calc($padding-x);
  @include breakpoint(large) {
    padding: 0 rem-calc($padding-x-large);
  }
}

@mixin padding-y {
  padding: rem-calc($padding-y) 0;
  @include breakpoint(large) {
    padding: rem-calc($padding-y-large) 0;
  }
}

@mixin padding-none {
  padding: 0 !important;
}

// transforms

@mixin transform-visible {
  opacity: 1;
  transition: visibility 0s, opacity 200ms, transform 400ms;
  visibility: visible;
}

@mixin transform-hidden {
  opacity: 0;
  transition: visibility 0s 400ms, opacity 200ms, transform 400ms;
  visibility: hidden;
}

@mixin border-bars {
  border-bottom: 1px solid $color-gray-light;
  border-top: 1px solid $color-gray-light;
}
