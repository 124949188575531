.c-loadmore {
  &-container {
    background: $color-gray-light;
    height: 1px;
    margin: $xl-space 0 $m-space;
    position: relative;
    width: 100%;
  }

  &-button {
    border-radius: 25px;
    color: $color-white;
    display: block;
    font-size: rem-calc(14);
    left: 50%;
    margin: auto;
    padding: $xs-space $m-space;
    position: absolute;
    text-align: center;
    top: calc(50% - 24px);
    transform: translateX(-50%);

    &:hover,
    &:focus {
      background: $color-gray-dark;
    }
  }
}
