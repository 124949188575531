.c-cta {
  &-list {
    @media screen and (max-width: $tablet-width) and (min-width: $mobile-width) {
      width: calc(50% - 16px);
    }

    ul {
      list-style: none;
      padding: 0;
    }

    &-item {
      @include flex-column;
      margin-bottom: $s-space;
    }
  }
}
