.c-related {

  &-articles {
    @include flex-center;
    border-bottom: 1px solid $color-gray-light;
    border-top: 1px solid $color-gray-light;
    margin-bottom: $m-space;
    padding: $s-space 0;

    @media screen and (max-width: $tablet-width) {
      justify-content: flex-start;
    }
  }

  &-article {

    &-card {
      @include flex-column;

      @media screen and (max-width: $tablet-width) {
        flex-direction: row;
      }

      @media screen and (max-width: $mobile-width) {
        border-bottom: 1px solid $color-gray-light;
        padding: $s-space 0;
      }

      .heading-large {
        color: $color-link-hover;
        font-size: rem-calc(22);
        line-height: 28px;
        text-decoration: none;

        &:hover,
        &:focus {
          color: $color-link;
          text-decoration: underline;
        }
      }
    }

    &-title {
      @include flex-column;
      margin-bottom: $m-space;

      .heading-large {
        font-size: rem-calc(28px);
        line-height: 34px;
      }

      @media screen and (max-width: $tablet-width) {
        font-size: rem-calc(20);
        margin-bottom: $s-space;
      }
    }

    &-image {
      margin-bottom: $m-space;

      @media screen and (max-width: $tablet-width) and (min-width: $mobile-width) {
        margin-bottom: 0;
        max-width: 220px;
        min-width: 220px;
      }

      @media screen and (max-width: $mobile-width){
        margin-bottom: 0;
        max-width: 100px;
        min-width: 100px;
      }
    }

    &-content {
      @media screen and (max-width: $tablet-width) and (min-width: $mobile-width) {
        border-bottom: 1px solid $color-gray-light;
        margin-bottom: $s-space;
        margin-left: $l-space;
        padding: $s-space 0 $m-space;
      }

      @media screen and (max-width: $tablet-width) {
        flex-grow: 1;
      }

      @media screen and (max-width: $mobile-width) {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 15px;
        padding-bottom: 0;
      }
    }
  }
}
