.hide-for-mobile {
  @media screen and (max-width: $mobile-width) {
    display: none;
  }
}

.static {
  overflow-y: hidden;
}

.border {
  &-bottom {
    border-bottom: 1px solid $color-gray-light;
  }
}

.no {
  &-border {
    &-bottom {
      border-bottom: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}

.bold {
  font-weight: bold !important;
}

.hidden {
  display: none;
}
